<template>
  <v-col v-if="isVisible || noData">
    <graph-card v-if="isVisible">
      <vue-highcharts
        class="mt-8"
        v-if="(!isLoading && isVisible) || (!isLoading && noData)"
        :highcharts="Highcharts"
        :options="config"
        ref="heatmapChart"
        style="width: 100%"
      ></vue-highcharts>
    </graph-card>
    <div v-if="noData" class="no-data">
      {{ $t("DASHBOARD.WIDGET.NO_DATA") }}
    </div>
  </v-col>
</template>

<script>
import GraphCard from "@/views/Dashboard/components/GraphCard";
import VueHighcharts from "vue2-highcharts";
import Highcharts from "highcharts";
import Heatmap from "highcharts/modules/heatmap";
import { mapActions } from "vuex";
import merge from "lodash.merge";
import { heatmapConfig } from "./heatmapConfig";

Heatmap(Highcharts);

export default {
  name: "Heatmap",
  components: { GraphCard, VueHighcharts },
  data() {
    return {
      isLoading: false,
      Highcharts: Highcharts,
      config: heatmapConfig(),
      isVisible: true,
      noData: false
    };
  },
  created() {
    const { projectId } = this.$route.params;
    this.isLoading = true;
    this.getTableData({ projectId })
      .then(response => {
        if (response.data.error) {
          this.isVisible = false;
          this.noData = true;
        } else if (response.data.series[0].data.length === 0) {
          this.isVisible = false;
        } else {
          let exportXLSX = {
            exporting: {
              buttons: {
                contextButton: {
                  menuItems: [
                    "downloadPNG",
                    {
                      textKey: "downloadXLSX",
                      onclick: () => this.handleExport()
                    }
                  ]
                }
              },
              filename: "correlation-table"
            }
          };
          this.config = merge(response.data, exportXLSX, this.config);
        }
      })
      .catch(error => {
        console.log(error);
        this.$notifier.notify({
          message: `${projectId} is invalid`,
          color: "error"
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    ...mapActions({
      getTableData: "dashboards/getTableData",
      exportXlsxDataTable: "dashboards/exportXlsxDataTable"
    }),
    handleExport() {
      const { projectId } = this.$route.params;
      this.exportXlsxDataTable({ projectId });
    }
  }
};
</script>

<style lang="scss">
.no-data {
  align-items: center;
  display: flex;
  font-size: 24px;
  height: 300px;
  justify-content: center;
}
</style>
