<template>
  <v-row v-if="!isLoading" class="justify-center">
    <div
      v-for="(config, index) in donutConfigs"
      :key="index"
      :class="seriesLength > 4 ? 'col-3' : 'col-' + 12 / seriesLength"
    >
      <graph-card>
        <vue-highcharts
          v-if="!isLoading"
          :highcharts="Highcharts"
          :options="config"
          ref="donutChart"
        ></vue-highcharts>
      </graph-card>
    </div>
  </v-row>
</template>

<script>
import GraphCard from "@/views/Dashboard/components/GraphCard";
import VueHighcharts from "vue2-highcharts";
import Highcharts from "highcharts";
import { mapActions } from "vuex";
import { donutConfig } from "@/views/Dashboard/components/Donut/donutChartConfig";
import merge from "lodash.merge";
import More from "highcharts/highcharts-more.js";
import solidGauge from "highcharts/modules/solid-gauge.js";
import slugify from "slugify";

More(Highcharts);
solidGauge(Highcharts);

export default {
  name: "Donut",
  components: { GraphCard, VueHighcharts },
  data() {
    return {
      seriesLength: undefined,
      isLoading: false,
      Highcharts: Highcharts,
      config: donutConfig(),
      donutConfigs: []
    };
  },
  created() {
    const { projectId } = this.$route.params;
    this.isLoading = true;
    this.getDonutData({ projectId })
      .then(response => {
        this.seriesLength = response.data.data.length;
        for (let i = 0; i < this.seriesLength; i++) {
          let name = response.data.data[i].title.text;
          let title = {
            xAxis: {
              categories: [name]
            },
            exporting: {
              filename: slugify(`pillar-${name}`, { lower: true })
            }
          };
          this.donutConfigs[i] = merge(
            response.data.data[i],
            this.config,
            title
          );
        }
      })
      .catch(error => {
        console.log(error);
        this.$notifier.notify({
          message: `${projectId} is invalid`,
          color: "error"
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    ...mapActions({
      getDonutData: "dashboards/getDonutData"
    })
  }
};
</script>
