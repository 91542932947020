<template>
  <v-card class="transparent" outlined min-height="200" width="100%">
    <slot />
  </v-card>
</template>

<script>
export default {
  name: "GraphCard"
};
</script>

<style lang="scss">
.highcharts-credits {
  display: none;
}
</style>
