import { roundValue } from "@/helpers/helpers";

export const heatmapConfig = () => ({
  chart: {
    backgroundColor: "transparent",
    type: "heatmap",
    marginTop: 80,
    marginBottom: 80,
    plotBorderWidth: 1
  },
  title: {
    text: "Correlation table",
    style: {
      fontSize: "18px",
      color: "#01014D"
    }
  },
  colorAxis: {
    min: -1,
    max: 1,
    minColor: "#E6E6F6",
    maxColor: "#3B77CF"
  },
  legend: {
    align: "right",
    layout: "vertical",
    margin: 0,
    verticalAlign: "top",
    y: 25,
    symbolHeight: 280
  },
  plotOptions: {
    series: {
      enableMouseTracking: false
    },
    heatmap: {
      dataLabels: {
        formatter: function() {
          return (this.point.value && roundValue(this.point.value, 2)) || "—";
        },
        style: {
          fontSize: "18px",
          strokeOpacity: 0.3
        }
      }
    }
  },
  series: [
    {
      dataLabels: {
        enabled: true,
        color: "#FFFFFF"
      }
    }
  ],
  tooltip: {
    enabled: false
  },
  xAxis: {
    opposite: true,
    labels: {
      style: {
        color: "#3E485C",
        fontSize: "14px"
      }
    }
  },
  yAxis: {
    labels: {
      style: {
        color: "#3E485C",
        fontSize: "14px"
      }
    },
    title: {
      text: " "
    }
  }
});
