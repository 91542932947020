import { roundValue } from "@/helpers/helpers";

export const gridConfig = () => ({
  chart: {
    type: "bubble",
    backgroundColor: "transparent",
    plotBackgroundColor: "#FFFFFF",
    plotAreaWidth: 400,
    plotAreaHeight: 400,
    height: 500,
    resetZoomButton: {
      position: {
        align: "right",
        verticalAlign: "top",
        y: -4,
        x: -40
      }
    }
  },
  title: {
    style: {
      color: "#01014D"
    }
  },
  series: [
    {
      className: "line-in-grid",
      type: "line",
      data: [
        [0, 0],
        [100, 100]
      ],
      includeInDataExport: false
    }
  ],
  plotOptions: {
    bubble: {
      maxSize: 30,
      marker: {
        fillColor: "#75AAF6",
        fillOpacity: 0.8
      },
      dataLabels: {
        style: {
          color: "#01014D",
          fontSize: "12px"
        }
      }
    },
    line: {
      color: "rgba(204, 204, 204, 1)",
      dashStyle: "longdash",
      enableMouseTracking: false,
      lineWidth: 1,
      marker: {
        enabled: false
      }
    },
    series: {
      dataLabels: {
        enabled: true,
        format: "{point.name}",
        y: 25
      }
    }
  },
  xAxis: {
    min: 0,
    max: 100,
    gridLineWidth: 1,
    tickInterval: 25,
    title: {
      style: {
        color: "#01014d",
        fontSize: "16px",
        fontWeight: "bold"
      }
    },
    plotLines: [
      {
        color: "#01014d",
        dashStyle: "longdash",
        width: 2,
        value: 50,
        zIndex: 3
      }
    ]
  },
  yAxis: {
    min: 0,
    max: 100,
    gridLineWidth: 1,
    tickInterval: 25,
    title: {
      style: {
        color: "#01014d",
        fontSize: "16px",
        fontWeight: "bold"
      }
    },
    plotLines: [
      {
        color: "#01014d",
        dashStyle: "longdash",
        width: 2,
        value: 50,
        zIndex: 3
      }
    ]
  },
  legend: {
    enabled: false
  },
  tooltip: {
    formatter: function() {
      let xAxis = this.series.xAxis.axisTitle.textStr;
      let yAxis = this.series.yAxis.axisTitle.textStr;
      let name = this.key;
      return `<b>${name}</b><br>${xAxis}: ${roundValue(
        this.x,
        2
      )}<br>${yAxis}: ${roundValue(this.y, 2)}`;
    }
  },
  exporting: {
    buttons: {
      contextButton: {
        menuItems: [
          "downloadPNG",
          {
            textKey: "downloadXLSX",
            onclick: function() {
              this.downloadXLSX();
            }
          }
        ]
      }
    },
    csv: {
      columnHeaderFormatter: function(item, key) {
        const xAxis =
          item.chart.xAxis[0] && item.chart.xAxis[0].userOptions.title.text;
        const yAxis =
          item.chart.yAxis[0] && item.chart.yAxis[0].userOptions.title.text;
        if (key === "x") {
          return xAxis;
        } else if (key === "y") {
          return yAxis;
        } else {
          return "";
        }
      }
    }
  }
});
