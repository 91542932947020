import { roundValue } from "@/helpers/helpers";

export const donutConfig = () => ({
  chart: {
    type: "solidgauge",
    backgroundColor: "transparent"
  },
  title: {
    style: {
      fontSize: "18px",
      color: "#01014D"
    }
  },
  tooltip: {
    borderWidth: 0,
    backgroundColor: "none",
    shadow: false,
    pointFormatter: function() {
      return (
        "<span>" +
        this.series.name +
        "<br>" +
        "<span><b>" +
        roundValue(this.y, 2) +
        "%" +
        "</b></span></span>"
      );
    },
    positioner: function(labelWidth) {
      return {
        x: (this.chart.chartWidth - labelWidth) / 2,
        y: this.chart.plotHeight / 2 + 20
      };
    }
  },
  pane: {
    startAngle: 0,
    endAngle: 360,
    background: [
      {
        outerRadius: "100%",
        innerRadius: "85%",
        backgroundColor: "rgba(79, 146, 244, 0.35)",
        borderWidth: 0
      },
      {
        outerRadius: "85%",
        innerRadius: "70%",
        backgroundColor: "rgba(35, 193, 55, 0.35)",
        borderWidth: 0
      },
      {
        outerRadius: "70%",
        innerRadius: "55%",
        backgroundColor: "rgba(223, 54, 166, 0.35)",
        borderWidth: 0
      },
      {
        outerRadius: "55%",
        innerRadius: "40%",
        backgroundColor: "rgba(10, 80, 116, 0.35)",
        borderWidth: 0
      }
    ]
  },
  yAxis: {
    min: 0,
    max: 100,
    lineWidth: 0,
    tickPositions: []
  },
  xAxis: {
    title: {
      text: " "
    }
  },
  plotOptions: {
    solidgauge: {
      dataLabels: {
        enabled: false
      },
      linecap: "round",
      stickyTracking: false,
      rounded: true
    }
  },
  series: [
    {
      data: [
        {
          color: "rgba(79, 146, 244, 1)",
          radius: "100%",
          innerRadius: "85%"
        }
      ]
    },
    {
      data: [
        {
          color: "rgba(35, 193, 55, 1)",
          radius: "85%",
          innerRadius: "70%"
        }
      ]
    },
    {
      data: [
        {
          color: "rgba(223, 54, 166, 1)",
          radius: "70%",
          innerRadius: "55%"
        }
      ]
    },
    {
      data: [
        {
          color: "rgba(10, 80, 116, 1)",
          radius: "55%",
          innerRadius: "40%"
        }
      ]
    }
  ],
  exporting: {
    buttons: {
      contextButton: {
        menuItems: [
          "downloadPNG",
          {
            textKey: "downloadXLSX",
            onclick: function() {
              this.downloadXLSX();
            }
          }
        ]
      }
    }
  }
});
