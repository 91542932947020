<template>
  <graph-card>
    <vue-highcharts
      v-if="!isLoading"
      :highcharts="Highcharts"
      :options="config"
      ref="barVitalityChart"
    ></vue-highcharts>
  </graph-card>
</template>

<script>
import GraphCard from "@/views/Dashboard/components/GraphCard";
import VueHighcharts from "vue2-highcharts";
import Highcharts from "highcharts";
import { mapActions } from "vuex";
import { barConfig } from "@/views/Dashboard/components/Bar/barChartConfig";
import merge from "lodash.merge";

export default {
  name: "VitalityScoreGraph",
  components: { GraphCard, VueHighcharts },
  data() {
    return {
      isLoading: false,
      Highcharts: Highcharts,
      config: barConfig(),
      baseConfig: {
        title: { text: "Vitality" },
        plotOptions: {
          series: {
            color: "rgba(79, 146, 244, 1)"
          }
        },
        exporting: {
          filename: "bar-vitality"
        }
      }
    };
  },
  created() {
    const { projectId } = this.$route.params;
    this.config = merge(this.config, this.baseConfig);
    this.isLoading = true;
    this.getBarData({ projectId, kind: "pillarVitality" })
      .then(response => {
        this.config = merge(response.data, this.config);
      })
      .catch(error => {
        console.log(error);
        this.$notifier.notify({
          message: `${projectId} is invalid`,
          color: "error"
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    ...mapActions({
      getBarData: "dashboards/getBarData"
    })
  }
};
</script>
