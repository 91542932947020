import { roundValue } from "@/helpers/helpers";

export const barConfig = () => ({
  chart: {
    backgroundColor: "transparent",
    type: "bar"
  },
  plotOptions: {
    bar: {
      dataLabels: {
        enabled: true,
        formatter: function() {
          return roundValue(this.y, 2);
        },
        style: {
          color: "#3E485C",
          fontSize: "13px"
        }
      }
    }
  },
  title: {
    style: {
      fontSize: "18px",
      color: "#01014D"
    }
  },
  xAxis: {
    labels: {
      style: {
        color: "#3E485C",
        fontSize: "14px"
      }
    }
  },
  yAxis: {
    min: 0,
    max: 100,
    labels: { format: null },
    gridLineColor: "rgba(0, 0, 0, 0.15)",
    tickInterval: 25,
    title: {
      text: " "
    }
  },

  legend: {
    enabled: false
  },
  tooltip: {
    enabled: false,
    formatter: function() {
      return this.x + ": <b>" + roundValue(this.y, 2);
    }
  },
  exporting: {
    buttons: {
      contextButton: {
        menuItems: [
          "downloadPNG",
          {
            textKey: "downloadXLSX",
            onclick: function() {
              this.downloadXLSX();
            }
          }
        ]
      }
    },
    csv: {
      columnHeaderFormatter: function(item, key) {
        let name = item.name;
        if (key === "y") {
          return name;
        } else {
          return "";
        }
      }
    }
  }
});
