<template>
  <v-card v-if="project" class="mb-10" flat outlined>
    <v-card-text class="d-flex">
      <div>Project: {{ project.name }} ({{ project._market.name }})</div>
      <div class="ml-1" v-if="showAuthor">by {{ project._user.fullname }}</div>
      <v-spacer />
      <div>
        Created at: {{ $moment(project.createdAt).format("hh:ss A, MMM D, Y") }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ProjectDetails",
  props: {
    project: {
      required: true
    },
    showAuthor: {
      type: Boolean,
      default: false
    }
  }
};
</script>
