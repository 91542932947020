<template>
  <v-col cols="6" v-if="isVisible">
    <graph-card>
      <vue-highcharts
        v-if="!isLoading"
        :highcharts="Highcharts"
        :options="config"
        ref="gridProminenceMoodChart"
      ></vue-highcharts>
    </graph-card>
  </v-col>
</template>

<script>
import GraphCard from "@/views/Dashboard/components/GraphCard";
import VueHighcharts from "vue2-highcharts";
import Highcharts from "highcharts";
import { mapActions } from "vuex";
import { gridConfig } from "@/views/Dashboard/components/Grid/gridChartConfig";
import merge from "lodash.merge";

export default {
  name: "ProminenceMoodGraph",
  components: { GraphCard, VueHighcharts },
  data() {
    return {
      isLoading: false,
      isVisible: true,
      Highcharts: Highcharts,
      config: gridConfig(),
      baseConfig: {
        title: {
          text: "Prominence vs Mood"
        },
        xAxis: {
          title: {
            text: "Prominence"
          }
        },
        yAxis: {
          title: {
            text: "Mood"
          }
        },
        exporting: {
          filename: "grid-prominence-vs-mood"
        }
      }
    };
  },
  created() {
    const { projectId } = this.$route.params;
    this.config = merge(this.config, this.baseConfig);
    this.isLoading = true;
    this.getGridData({ projectId, x: "pillarProminence", y: "pillarMood" })
      .then(response => {
        if (response.data.data.length === 0) {
          this.isVisible = false;
        } else {
          this.config.series = this.config.series.concat(response.data);
        }
      })
      .catch(error => {
        console.log(error);
        this.$notifier.notify({
          message: `${projectId} is invalid`,
          color: "error"
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    ...mapActions({
      getGridData: "dashboards/getGridData"
    })
  }
};
</script>
